<template>
    <button
        :type="props?.type"
        :class="buttonClasses"
        :disabled="props?.disabled"
        @click="$emit('action')"
    >
        <template v-if="showPlusIcon">
            <NfsIcon
                icon="plus-small"
                size="small"
            />
        </template>
        <slot />
    </button>
</template>

<script lang="ts" setup>
import { type ButtonHTMLAttributes } from 'vue';

const props = defineProps({
    color: {
        type: String,
        default: 'brand'
    },
    size: {
        type: String,
        default: 'base'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    type: {
        type: String as PropType<ButtonHTMLAttributes['type']>,
        default: 'button'
    },
    fullWidth: {
        type: Boolean,
        default: false
    },
    showPlusIcon: {
        type: Boolean,
        default: false
    }
});
defineEmits<{
  (e: 'action'): void
}>();
const sizeClasses : Record<string, string> = {
    xSmall: 'rounded px-2 py-1 text-xs',
    small: 'rounded px-2 py-1 text-sm',
    base: 'rounded-md px-2.5 py-1.5 text-sm',
    medium: 'rounded-md px-3 py-2 text-sm',
    large: 'rounded-md px-3.5 py-2.5 text-sm'
};
const colorClasses: Record<string, string> = {
    brand: 'bg-brand-300 hover:bg-brand-400 focus-visible:outline-brand-300',
    transparent: 'bg-transparent border border-neutral-300 hover:bg-neutral-200'
};
const sizeClass = computed(() => sizeClasses[props.size] || 'base');
const colorClass = computed(() => colorClasses[props.color] || 'brand');
const buttonClasses = computed(() => {
    const classes = [ props.disabled && 'text-neutral-900/40 bg-neutral-900/20 hover:bg-transparent',
        'flex items-center justify-center font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
        colorClass.value,
        sizeClass.value,
        props.fullWidth && 'w-full'
    ];
    return classes;
});
</script>
